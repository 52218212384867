<template>
  <a-modal
    :title="handle === 'add' ? '新增' : handle === 'edit' ? '编辑' : '查看'"
    style="top: 8px"
    :width="1000"
    v-model="visible"
    :footer="null"
    :maskClosable="false"
  >
    <a-tabs type="card" @change="callback" :defaultActiveKey="keyIndex" :activeKey="currKeyIndex">
      <a-tab-pane key="2" tab="联系人信息" v-if="keyIndex != '2' ? false:true">
        <div v-for="(item, index) in contactsList" :key="index">
          <a-row>
            <a-col :span="10">
              <a-form-model-item label="联系人" prop="" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16 }">
                <a-input  v-model="item.contacts" placeholder="请输入联系人"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="10">
              <a-form-model-item label="联系电话"  :labelCol="{ span: 7 }" :wrapperCol="{ span: 16 }">
                <a-input  v-model="item.phone" placeholder="请输入联系电话" @blur="blurPurchasePhone1"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="3" style="text-align: right; padding: 10px 0px 0px 0px">
              <a @click="setPersonDefault(index)" v-if="!item.flagDefault">设为默认</a>
              <a-tag color="#f50" v-if="item.flagDefault">默认</a-tag>
              <a-divider type="vertical" />
              <a @click="onDelPerson(item)">删除</a>
            </a-col>
          </a-row>
        </div>
        <a-row>
          <a-col :md="12">
            <a-button :disabled="handle == 'check'" type="primary" @click="addPersonRow()">添加联系人</a-button>
          </a-col>
        </a-row>

      </a-tab-pane>
      <a-tab-pane key="3" tab="收货地址"  v-if="keyIndex != '3' ? false:true">
        <div v-for="(item, index) in deliveryAddressesList" :key="index">
          <a-row>
            <a-col :span="10">
              <a-form-model-item label="收货人" prop="" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16 }">
                <a-input :disabled="handle == 'check'" v-model="item.contacts" placeholder="请输入收货人"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="10">
              <a-form-model-item label="联系方式"  :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
                <a-input
                  :disabled="handle == 'check'"
                  v-model="item.phone"
                  placeholder="请输入收货人联系方式"
                  @blur="blurPurchasePhone2"
                ></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :span="3" style="text-align: right; padding: 10px 0px 0px 0px">
              <a @click="setAddressDefault(index)" v-if="!item.flagDefault">设为默认</a>
              <a-tag color="#f50" v-if="item.flagDefault">默认</a-tag>
              <a-divider type="vertical" />
              <a @click="onDelAddres(item)">删除</a>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="10">
              <a-form-model-item label="收货地址" prop="" :labelCol="{ span: 7 }" :wrapperCol="{ span: 16 }">
                <PCD
                  ref="tagItem"
                  :disabled="handle == 'check'"
                  placeholder="请选择区域"
                  :province.sync="item.province"
                  :city.sync="item.city"
                  :district.sync="item.area"
                  :provinceId.sync="item.provinceId"
                  :cityId.sync="item.cityId"
                  :districtId.sync="item.areaId"
                >
                </PCD>
              </a-form-model-item>
            </a-col>
            <a-col :span="10">
              <a-form-model-item label="详细地址" prop="" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
                <a-textarea :disabled="handle == 'check'" :max-length="50" v-model="item.address" placeholder="请输入详细地址"></a-textarea>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-divider />
        </div>
        <a-row>
          <a-col :md="12">
            <a-button :disabled="handle == 'check'" type="primary" @click="addAddressRow()">添加收货地址</a-button>
          </a-col>
        </a-row>
      </a-tab-pane>


      <a-tab-pane key="4" tab="发票信息" v-if="keyIndex != '4' ? false:true">
        <div v-for="(item, index) in invoiceList" :key="index">
          <a-row>
            <a-col :md="12">
              <a-form-model-item label="抬头类型" prop="" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
                <a-radio-group
                  :disabled="handle === 'check' ? true : false"
                  :options="consigneeOptions"
                  v-model="item.receivingType"
                  defaultValue="1"
                />
              </a-form-model-item>
            </a-col>
            <a-col :md="2">
              <a-form-model-item label="" prop="" :labelCol="{ span: 0 }" :wrapperCol="{ span: 18 }">
                <a @click="setInvoiceDefault(index)" v-if="!item.flagDefault">设为默认</a>
                <a-tag color="#f50" v-if="item.flagDefault">默认</a-tag>
              </a-form-model-item>
            </a-col>
            <a-col :md="2">
              <a-form-model-item label="" prop="" :labelCol="{ span: 0 }" :wrapperCol="{ span: 18 }">
                <a @click="onDelInvoice(item)">删除</a>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :md="12">
              <a-form-model-item
                :label="item.receivingType == 1 ? '发票抬头' : '公司名称'"
                prop=""
                :labelCol="{ span: 6 }"
                :wrapperCol="{ span: 18 }"
              >
                <a-input :disabled="handle === 'check' ? true : false" v-model="item.invoiceTitle"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="12" v-if="item.receivingType == 2">
              <a-form-model-item label="纳税人识别码" prop="" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
                <a-input :disabled="handle === 'check' ? true : false" v-model="item.taxpayerNumber"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :md="12" v-if="item.receivingType == 2">
              <a-form-model-item label="注册电话" prop="" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
                <a-input :disabled="handle === 'check' ? true : false" v-model="item.regPhone"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="12" v-if="item.receivingType == 2">
              <a-form-model-item label="注册地址" prop="" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
                <a-input :disabled="handle === 'check' ? true : false" v-model="item.regAddress"></a-input>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :md="12" v-if="item.receivingType == 2">
              <a-form-model-item label="开户银行" prop="" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
                <a-input :disabled="handle === 'check' ? true : false" v-model="item.depositBank"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="12" v-if="item.receivingType == 2">
              <a-form-model-item label="银行账号" prop="" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
                <a-input :disabled="handle === 'check' ? true : false" v-model="item.bankAccount"></a-input>
              </a-form-model-item>
            </a-col>
            <a-col :md="12" v-if="item.receivingType == 2">
              <a-form-model-item label="开票类型" prop="" :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }">
                <a-radio-group
                  :disabled="handle === 'check' ? true : false"
                  :options="invoiceOptions"
                  v-model="item.invoiceType"
                  defaultValue="1"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-divider />
        </div>
        <a-row>
          <a-col :md="12">
            <a-button :disabled="handle == 'check'" type="primary" @click="addInvoiceRow()">添加发票信息</a-button>
          </a-col>
        </a-row>
      </a-tab-pane>


    </a-tabs>
    <div class="footer-bts" v-if="handle != 'check'">
          <a-button  @click="handleCancel">取消</a-button>
          <a-button type="primary" :loading="loading" @click="toSubmit()">保存</a-button>

        </div>
  </a-modal>
</template>
<script>
import { selectByIdCustomerInfo } from '../api/CustomerInfoApi'
import SelectParentDealerModal from '../../common/SelectParentDealerModal.vue'
export default {
  components: {
    SelectParentDealerModal
  },
  data() {
    return {
      url: '',
      visible: false,
      handle: 'add',
      rowData: {},
      classifyNum:'',
      loading: false,
      options: [],
      dealerOptions: [
        {
          value: 1,
          label: '中心店',
        },
        {
          value: 2,
          label: '核心店',
        },
        {
          value: 3,
          label: '一般店',
        },
        {
          value: 4,
          label: '核心店（预备）',
        },
      ],
      consociationOptions: [
        {
          value: 1,
          label: '合作中',
        },
        {
          value: 2,
          label: '合作终止',
        },
      ],
      RegOff: [],
      // 表单验证
      formRule: {
        name: [{ required: true, message: '请输入经销商全称', trigger: 'blur' }],
        dealerCode: [{ required: true, message: '请输入经销商编码', trigger: 'blur' }],
        // phone: [
        //   { required: true, pattern: new RegExp(/^1[3456789]\d{9}$/), message: '请输入正确的手机号', trigger: 'blur' },
        // ],
        Reg:[{ required: true, message: '大区及事务所', trigger: 'blur' }],
        numberOfEmployees: [{ required: true, message: '请输入可创建员工数量', trigger: 'blur' }],
        receiptInvoice: [{ required: true, message: '所属区域', trigger: 'change' }],
        templateId: [{ required: true, message: '请选择权限组', trigger: 'change' }],
        account: [{ required: true, message: '请输入帐号', trigger: 'blur' }],
        password: [{ required: true,min:6,max:16, message: '请输入6到16位密码' }],


        // taxCode: [
        //   { required: true, pattern: new RegExp(/^[0-9]{1,18}$/), message: '请输入纳税识别号', trigger: 'blur' },
        // ],
      },
      templateList: [],
      contactsList: [],
      deliveryAddressesList: [],
      invoiceList: [],
      consigneeOptions: [
        {
          label: '个人',
          value: 1,
        },
        {
          label: '公司',
          value: 2,
        },
      ],
      invoiceOptions: [
        {
          label: '小规模',
          value: 1,
        },
        {
          label: '一般纳税人',
          value: 2,
        },
      ],
      keyIndex: '1',
      currKeyIndex: '1',
    }
  },
  methods: {
    //设置默认收货地址
    setAddressDefault(index) {
      for (let i = 0; i < this.deliveryAddressesList.length; i++) {
        if (index == i) {
          this.deliveryAddressesList[index].flagDefault = true
        } else {
          this.deliveryAddressesList[i].flagDefault = false
        }
      }
    },
    //设置默认发票信息
    setInvoiceDefault(index) {
      for (let i = 0; i < this.invoiceList.length; i++) {
        if (index == i) {
          this.invoiceList[index].flagDefault = true
        } else {
          this.invoiceList[i].flagDefault = false
        }
      }
    },
    //删除发票信息行
    onDelInvoice(item) {
      this.invoiceList.splice(
        this.invoiceList.findIndex((e) => {
          return e == item
        }),
        1
      )
      if (item.flagDefault) {
        if (this.invoiceList.length > 0) {
          this.invoiceList[0].flagDefault = true
        }
      }
    },
    //添加发票信息行
    addInvoiceRow() {
      this.invoiceList.push({
        bankAccount: '',
        depositBank: '',
        flagDefault: false,
        invoiceTitle: '',
        receivingType: 1,
        invoiceType: '',
        regAddress: '',
        regPhone: '',
        taxpayerNumber: '',
      })
    },
    //添加收货地址行
    addAddressRow() {
      this.deliveryAddressesList.push({
        province: '',
        provinceId: 0,
        city: '',
        cityId: 0,
        area: '',
        areaId: 0,
        address: '',
        contacts: '',
        phone: '',
      })
    },
    //删除收货地址行
    onDelAddres(item) {
      this.deliveryAddressesList.splice(
        this.deliveryAddressesList.findIndex((e) => {
          return e == item
        }),
        1
      )
      if (item.flagDefault) {
        if (this.deliveryAddressesList.length > 0) {
          this.deliveryAddressesList[0].flagDefault = true
        }
      }
    },
    //设置默认联系人
    setPersonDefault(index) {
      for (let i = 0; i < this.contactsList.length; i++) {
        if (index == i) {
          this.contactsList[index].flagDefault = true
        } else {
          this.contactsList[i].flagDefault = false
        }
      }
    },
    //删除联系人
    onDelPerson(item) {
      this.contactsList.splice(
        this.contactsList.findIndex((e) => {
          return e == item
        }),
        1
      )
      if (item.flagDefault) {
        if (this.contactsList.length > 0) {
          this.contactsList[0].flagDefault = true
        }
      }
    },
    // 联系人修改默认
    personClickFlagDefault(index) {
      if (this.contactsList[index].flagDefault) {
        this.contactsList[index].flagDefault = false
      } else {
        this.contactsList[index].flagDefault = true
      }
    },
    // 添加联系人行
    addPersonRow() {
      this.contactsList.push({ contacts: '', phone: '' })
    },
    callback(key) {
      this.currKeyIndex = key
      console.log(this.currKeyIndex)
    },

    toSelectConsumer(row) {
      this.row = row
      this.$set(this.rowData, 'parentId', row.id)
      this.$set(this.rowData, 'parentName', row.name)
      this.$set(this.rowData, 'templateId', row.templateId)
    },

    toNoSelectConsumer() {
      this.$set(this.rowData, 'parentId', '0')
      this.$set(this.rowData, 'parentName', '-无-')
      this.$set(this.rowData, 'templateId', '')
    },


    // 获取行数据
    setRowData(row,classify, handle, index) {
      this.classifyNum = classify
      if (index) {
        this.keyIndex = index
        this.currKeyIndex = index
      }
      const that = this
      this.handle = handle
      this.visible = true
      this.rowData = {}
      this.RegOff = []
      this.url = ''
      this.rowData.provinceId = ''
      //设置经销商分类  1为订单经销商  2为报备经销商
      this.rowData.dealerClassify = classify
      this.$nextTick(() => {
        this.$refs.tagItem.setPCD()
      })
      this.$set(this.rowData, 'num', 0)
      // 获取大区和事务所数据
      this.axios.get('/api/firm/mhiac/mhiacArea/areaFirmAll').then((res) => {
        this.options = res.body
      })
      // 获取角色模板数据
      this.axios.get('/api/base/dealer/dealerTemplate/listAll').then((res) => {
        this.templateList = res.body
      })
      if (handle == 'add') {
        this.$set(this.rowData, 'flagLinkgap', true)
        this.$set(this.rowData, 'flagCanUsedParentMoney', false)
        // this.$set(this.rowData, 'dealerType', 1)
        this.$set(this.rowData, 'cooperateStatus', 1)
      } else if (handle == 'edit' || handle == 'check') {
        selectByIdCustomerInfo(row.id).then((res) => {
          // 编辑或者查看
          this.rowData = res.body.dealerInfo
          this.contactsList = res.body.contactsList
          this.deliveryAddressesList = res.body.deliveryAddressesList
          this.invoiceList = res.body.invoiceList
          this.RegOff = [res.body.dealerInfo.areaId, res.body.dealerInfo.firmId]
          if (res.body.dealerInfo) {
            this.$set(this.rowData, 'provinceId', res.body.dealerInfo.provinceId)
            this.$set(this.rowData, 'province', res.body.dealerInfo.province)
            this.$set(this.rowData, 'cityId', res.body.dealerInfo.cityId)
            this.$set(this.rowData, 'city', res.body.dealerInfo.city)
            this.$set(this.rowData, 'countyId', res.body.dealerInfo.countyId)
            this.$set(this.rowData, 'county', res.body.dealerInfo.county)
            this.$set(this.rowData, 'address', res.body.dealerInfo.address)
            this.$nextTick(() => {
              this.$refs.tagItem.setPCD()
            })
          }
          this.$nextTick(() => {
            console.log(' ddd', that.$refs.tagItem)
            for (let i = 0; i < that.deliveryAddressesList.length; i++) {
              console.log(i)
              if(that.$refs.tagItem){
              that.$refs.tagItem[i].setPCD()
              }
            }
          })
          // if (that.deliveryAddressesList) {

          // for (let i = 0; i < res.body.deliveryAddressesList.length; i++) {
          //   this.$set(this.deliveryAddressesList[i], 'provinceId', res.body.deliveryAddressesList[i].provinceId)
          //   this.$set(this.deliveryAddressesList[i], 'province', res.body.deliveryAddressesList[i].province)
          //   this.$set(this.deliveryAddressesList[i], 'cityId', res.body.deliveryAddressesList[i].cityId)
          //   this.$set(this.deliveryAddressesList[i], 'city', res.body.deliveryAddressesList[i].city)
          //   this.$set(this.deliveryAddressesList[i], 'areaId', res.body.deliveryAddressesList[i].areaId)
          //   this.$set(this.deliveryAddressesList[i], 'area', res.body.deliveryAddressesList[i].area)
          //   this.$set(this.deliveryAddressesList[i], 'address', res.body.deliveryAddressesList[i].address)
          // this.$nextTick(() => {
          //   console.log(that.deliveryAddressesList);
          //   for (let i = 0; i < that.deliveryAddressesList.length; i++) {
          //     that.$refs.addressPCD[i].setPCD()
          //   }
          // })
          // }
          // }
          this.url = res.body.dealerInfo.dealerFiles
            ? res.body.dealerInfo.dealerFiles
              .map((e) => {
                return e.url
              })
              .toString()
            : ''

        })
      }
    },
    // 禁用弹框
    handleCancel() {
      this.visible = false
      this.url=''
      this.loading = false
      this.$refs.form.resetFields()
    },
    onChange(val, item) {
      this.rowData.areaId = val ? val[0] : ''
      this.rowData.firmId = val ? val[1] : ''
      this.rowData.area = item ? item[0].name : ''
      this.rowData.firmName = item ? item[1].name : ''
    },

    // 校验电话号码
    blurPurchasePhone1(){
      if(this.contactsList){
         this.contactsList.forEach(item=>{
             if(item.phone === ''){
               this.$message.warning('请输入联系电话信息')
                return false
             }else{
               var RegCellPhone = /^((13[0-9])|(14[5-9])|(15([0-3]|[5-9]))|(16(2|[5-7]))|(17[0-8])|(18[0-9])|(19([0-3]|[5-9])))\d{8}$|^0\d{2,3}-?\d{7,8}$/;
               var falg= item.phone.search(RegCellPhone);
                if (falg==-1){
	                  this.$message.warning('手机号格式不正确');
	                  return false;
	                }else{
	    	            return true;
                  }
             }
          })
      }
    },
    blurPurchasePhone2(){
      if(this.deliveryAddressesList.length > 0){
         this.deliveryAddressesList.forEach(item=>{
             if(item.phone === ''){
               this.$message.warning('请输入联系电话信息')
                return false
             }else{
               var RegCellPhone = /^((13[0-9])|(14[5-9])|(15([0-3]|[5-9]))|(16(2|[5-7]))|(17[0-8])|(18[0-9])|(19([0-3]|[5-9])))\d{8}$|^0\d{2,3}-?\d{7,8}$/;
               var falg= item.phone.search(RegCellPhone);
                if (falg==-1){
	                  this.$message.warning('手机号格式不正确');
	                  return false;
	                }else{
	    	            return true;
                  }
             }
          })
      }
    },
    // 表单提交
    toSubmit(status) {
       if(this.contactsList.length > 0){
         let Bulelist = this.contactsList.every(item=>{
             return item.contacts != '' && item.phone !=''
          })
          if(Bulelist === false){
             this.$message.warning('添加的各项‘联系人信息‘都是必填')
            return false
          }
        }
          if(this.deliveryAddressesList.length > 0){
         let Bulelist = this.deliveryAddressesList.every(item=>{
             return item.contacts != null && item.phone !=null && item.address != null && item.area != null
          })
          if(Bulelist === false){
             this.$message.warning('添加的各项‘收货地址信息’都是必填')
            return false
          }
        }
      if (this.keyIndex != undefined) {

        if (this.keyIndex == '2') {
          // 修改联系人
          this.axios
            .post('/api/dealer/dealer/dealerInfo/contact/update/' + this.rowData.id, this.contactsList)
            .then((res) => {
              this.$notification.success({ message: res.message })
              this.$emit('reload')
              this.visible = false
              this.loading = false
              return
            })
            .catch((err) => {
              this.loading = false
              return
            }).finally(()=>{
              this.loading = false
            })
        } else if (this.keyIndex == '3') {

          // 修改收货地址
          this.axios
            .post('/api/dealer/dealer/dealerInfo/deliveryAddress/update/' + this.rowData.id, this.deliveryAddressesList)
            .then((res) => {
              this.$notification.success({ message: res.message })
              this.$emit('reload')
              this.visible = false
              this.loading = false
              return
            })
            .catch((err) => {
              this.loading = false
              return
            }).finally(()=>{
              this.loading = false
            })
        } else if (this.keyIndex == '4') {
          // 修改发票
          this.axios
            .post('/api/dealer/dealer/dealerInfo/invoice/update/' + this.rowData.id, this.invoiceList)
            .then((res) => {
              this.$notification.success({ message: res.message })
              this.$emit('reload')
              this.visible = false
              this.loading = false
              return
            })
            .catch((err) => {
              this.loading = false
              return
            }).finally(()=>{
              this.loading = false
            })
        }
      }
    //   this.$refs.form.validate(async (valid) => {
        // if (!valid) {
        //   return
        // }
        // if (!this.rowData.countyId) {
        //   return this.$message.warning('请选择所属区域')
        // }
        // // this.loading = true
        // this.rowData.dealerFiles = this.url.split(',').map((item) => {
        //     return {
        //       url: item,
        //     }
        //   })
        // var data = {
        //   dealerInfo: this.rowData,
        //   contactsList: this.contactsList,
        //   deliveryAddressesList: this.deliveryAddressesList,
        //   invoiceList: this.invoiceList,
        // }
        // this.loading = true
        // if(this.handle === 'add'){
        //   this.axios.post('/api/dealer/dealer/dealerInfo/add',data).then(res => {
        //     this.$notification.success({ message: res.message })
        //     this.$emit('reload')
        //     this.visible = false
        //     this.loading = false
        //   }).catch((err)=>{
        //     this.loading = false
        //   })
        // }
        // if(this.handle === 'edit') {
        //   this.axios.post('/api/dealer/dealer/dealerInfo/edit',data).then(res => {
        //     this.$notification.success({ message: res.message })
        //     this.$emit('reload')
        //     this.visible = false
        //     this.loading = false
        //   }).catch((err)=>{
        //     this.loading = false
        //   })
        // }
        // const res = this.handle === 'add' ? await addCustomerInfo(data) : await editCustomerInfo(data)
        // console.log("res",res)
        // if (res.code === 200) {
        //   this.$notification.success({ message: res.message })
        //   this.$emit('reload')
        //   this.visible = false
        //   this.loading = false
        // } else {
        //   this.loading = false
        //   this.$notification.error({ message: res.message })
        // }
    //   })
    },
  },
  created() {},
}
</script>

<style lang="less" scoped>
.footer-bts {
  text-align: right;
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #e9e9e9;
}
</style>
